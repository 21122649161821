<template>
  <div class="d-flex align-center justify-center py-12">
    <v-card>
      <div class="d-flex flex-column align-center justify-center pa-12">
        <CircleIcon 
          size="100"
          background-color="#B81237"
        />
        <div class="mt-6">
          <h1>Page not found</h1>
        </div>
        <div class="mt-6">
          <v-btn
            text
            class="subtitle-1"
            to="/"
          >
            Return home
          </v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import CircleIcon from '@/components/CircleIcon';

export default {
  name: "NotFound",
  components: {
    CircleIcon
  }
}
</script>